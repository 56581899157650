@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.socials {
  $block-name: &;

  @include unlist;

  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  line-height: 0;
  margin: -12px -8px;

  &__item {
    margin-left: 4px;
    margin-right: 4px;
  }

  &__item-link {
    display: block;
    padding: 8px;
    color: $white-100;
    transition: color $transition-time;

    &:hover {
      color: $green-200;
    }

    &:active {
      color: $white-100;
    }
  }

  &__item-icon {
  }

  &--variant-primary {
    #{$block-name} {
      &__item-link {
        color: $purple-400;

        &:hover {
          color: $color-secondary;
        }

        &:active {
          color: $purple-400;
        }
      }
    }
  }

  &--variant-third {
    #{$block-name} {
      &__item-link {
        color: $light-gray-300;

        &:hover {
          color: $color-secondary;
        }

        &:active {
          color: $purple-400;
        }
      }
    }
  }

  //   #{$block-name} {
  //   }
}
